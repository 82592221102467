.bheading1
  {
    margin-top: 55px;
    margin-left: 20px;
  }
.bheading2
  {
    font-size:4vw;
    margin-top: 35px;
    margin-left: 20px;
  } 
.bheadingsmall
  {
  font-size:15vw;
  text-align: center;
  }
.column 
  {
   margin-left: 20px;
  }
  .column2 
  {
    margin-left: 20px;
  }
 
  .column4 
  {
    margin-left: 20px;
  }
.why{
    margin-top:50px;  
}
.whyimg{
    margin-left: auto;
    margin-right: auto;
  
}  
.line{
  margin-top: 90px;
  position: absolute;
  width: 100%;
  left: 0;
}
#nextbg
{
 
  opacity: 0.9;
  background-size: cover;
  position: relative;
  z-index: 1;
  background-attachment: fixed;
  padding-top: 100px;
  padding-bottom: 100px;

}
#nextbg-overlay {
  background:
  linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ),}
#text{
  text-align: justify; 
 }
 
   .card:hover {
     box-shadow: 0 0 50px hsla(0, 0%, 13%, 0.2); 
   
   }
   #cardimg{
     height: 50px;
     width: 50px; 
   }
   #list{
    list-style-type:none;
   }
   #footerbg{
    opacity: 0.9;
    background-size: cover;
    position: relative;
    z-index: 1;
   }
   #footerbg1{
    position: relative;
    background-size: cover;
    background-color: #181515e8;
    z-index: 2;
    height: 576px;
   }
   #transprant{
    background-color: #181515e8;
    margin-top: 18px;
    height: 539px;
     opacity: 0.7;
   }
   
 .tra
   #cmabg{
    opacity: 0.8;
   
    position: relative;
    z-index: 1;
    
   }
   #city{
    margin-left: auto;
    margin-right: auto;
  
   }  
   .card1{
    height: 100%;
    transition: box-shadow .3s;
    width: 100%; 
    border-radius:10px;
    border: 1px solid #ccc;
    background: #fff;
    padding-left: 40px;
    padding-right: 40px;
    
  }
  .progress{
    height: 100%;

  }
  .item{
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .title  
{  
        margin-bottom: 20px;  
        padding:20px  
}  
.img  
{  
        height: 260px;width:100%  
}  
.prideride{
  margin-left: 60px;
  margin-right: 60px;
}
.prideimg{
  margin-left: auto;
  margin-right: auto;
  width:430px;
  height: 300px;
}
.amenajari_interioare_css img{
  margin-top: 5px;
  margin-bottom: 1px;
  width: 100%;
  height: auto;
  border: 1px solid black;
  border-radius: 16px;
}
.card:hover
{
  height: auto;
  background-size: cover;
  position: relative;
} 
#layer1 {
  filter: brightness(0.3);
}
#layer {
  filter: brightness(0.9);
}
ul.demo {
 
  margin: 0;
  padding: 0;
}
.top {
  position: absolute;
  top: 70px;
  left: 30px;
}
.top-left {
  position: absolute;
  top: 150px;
  left: 30px;
}
#textonimg{
  position: relative;
  
  text-align: center;
  color: white;
}
.stretch{
  display: -webkit-flex; /* Safari */		
	display: flex; /* Standard syntax */
  min-height: 300px;
}
.flex{
  -webkit-flex: 1; /* Safari */
	-ms-flex: 1; /* IE 10 */
	flex: 1; /* Standard syntax */
  max-height: 100%;
}

ul a:active {
  background-color: #F9C70F;
}

ul a:hover{
 
  color: #F9C70F;
}
.carousel-indicators {
margin-bottom: -3rem;
}
.carousel-control-next-icon{
  margin-right: -15rem;
}
.carousel-control-prev-icon{
  margin-left: -15rem;
}
@media screen and (max-width: 480px) {
  .carousel-control-next-icon{
    margin-right: -73px;
  }
  .carousel-control-prev-icon{
    margin-left: -73px;
  }
}